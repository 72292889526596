import React, { Component} from "react";
import './Lunbo.css'

class LunBo extends Component{
    constructor(props){
        super(props)
        this.state={
            activeIndex: this.props.activeIndex,
        }
    }
    position(){
        switch (this.state.activeIndex){
            case 0:
                return "onePosition" ;
            case 1:
                return "twoPosition" ;
            case 2:
                return "therePosition" ;
            case 3:
                return "fourPosition";
            default: 
                break;
        }
    }
    handleTabChange = (index) => {
        this.setState({
            activeIndex:index
        })
    }
    render(){
        let{
            tabList,
            boxStyle
        }=this.props;
        return <div >
            <div className='tab-wapper'>
            {
              tabList.map((item, index) => {
                return (
                  <div 
                    key={`tab-${index}`}
                    className={`tab ${this.state.activeIndex===index?'active':''}`}
                    onClick={this.handleTabChange.bind(this, index)}
                  >
                    <p className='tab-text' >{item.name}</p>
                  </div>
                )
              })
            }
            </div>
            <div className={boxStyle}>
                <ul className={this.position()}>
                    {this.props.children}
                </ul>
            </div>
        </div>
    }
};

export default LunBo;