import React, { Component} from "react";
import "./App.scss";
import rockysaasLogo from './assets/img/rk-logo.png';
import logo from './assets/img/logo.png';
import wayImg from './assets/img/wayImg.png'
import serverCase from './assets/img/case.png';
import logo1 from './assets/img/logo1.png';
import scane from './assets/img/scane.png';
import LunBo from './Lunbo.js'

class App extends Component{
  constructor() {
    super();

    this.state = {
      menus:[
        {
          name: '首页',
          href:'#',
        },{
          name: '产品服务',
          href:'#product',
        },{
          name: '品牌案例',
          href:'#serverCase',
        },{
          name: '大屏演示',
          href:'https://www.wmnetwork.cc/screen/#/signwall?mid=wfp7e5qt',
        },
      ],
      activeId: 0,
      scenes:[
        {
          bg: require('./assets/img/scene-01.png'),
          name: '企业年会'
        }, {
          bg: require('./assets/img/scene-02.png'),
          name: '论坛峰会'
        },{
          bg: require('./assets/img/scene-03.png'),
          name: '发布会'
        },{
          bg: require('./assets/img/scene-04.png'),
          name: '教育培训'
        },{
          bg: require('./assets/img/scene-05.png'),
          name: '演出展会'
        },{
          bg: require('./assets/img/scene-06.png'),
          name: '创投路演'
        }
        
      ],
      products:[
        {
          name: '活动推广',
          desc:'提供活动报名、邀请函、短信提醒等工具服务',
          img: ''
        }, {
          name: '签到服务',
          desc:'提供扫码签到、人脸识别签到、多样签到模板等服务',
          img: ''
        }, {
          name: '大屏互动',
          desc:'提供14种多样互动玩法，全面提升会务的趣味品质性',
          img: ''
        }, 
      ],
      tabList: [
        {
          icon: 'solution1',
          activeIcon: 'solution10',
          name: '签到暖场',
        }, {
          icon: 'solution2',
          activeIcon: 'solution20',
          name: '互动抽奖',
        }, {
          icon: 'solution3',
          activeIcon: 'solution30',
          name: '投票评选',
        }, 
      ],
      tabInfoList: [
        [{
        img: require('./assets/img/01.png'),
        name: '3D签到墙',
        desc: '签到头像，大屏拼凑3D效果'
      }, {
        img: require('./assets/img/02.png'),
        name: '弹幕',
        desc: '弹幕上墙，增强互动氛围'
      }, {
        img: require('./assets/img/03.png'),
        name: '照片墙',
        desc: '轮播图片，回顾历程',
      }, {
        img: require('./assets/img/04.png'),
        name: '霸屏',
        desc: '图文上墙，霸屏焦点',
      }],
      [{
        img: require('./assets/img/05.png'),
        name: '酷炫抽奖',
        desc: '大屏走马灯抽奖，开启激动人心时刻'
      }, {
        img: require('./assets/img/06.png'),
        name: '红包雨',
        desc: '手机秒抢红包，现金秒到账'
      }, {
        img: require('./assets/img/07.png'),
        name: '摇一摇游戏',
        desc: '摇动收机，凭实力赢得奖品',
      }, {
        img: require('./assets/img/08.png'),
        name: '答题闯关',
        desc: '答题闯关，瓜分现金大奖',
      }],
      [{
        img: require('./assets/img/09.png'),
        name: '现场投票',
        desc: '手机投票，大屏实时统计'
      }, {
        img: require('./assets/img/10.png'),
        name: '嘉宾提问',
        desc: '提问上墙，告别传递麦克风'
      }, {
        img: require('./assets/img/11.png'),
        name: '评委打分',
        desc: '评委打分，大屏实时展示',
      }, {
        img: require('./assets/img/12.png'),
        name: '嘉宾打赏',
        desc: '好讲师，值得赞',
      }]
      ],
      currentTab:0,
      feedback:[
        {
          name: '墨迹天气',
          avatar: require('./assets/img/avatar-01.png'),
          desc1: '云嗨会务作为此次活动全程的技术支持方，为此次活动提供了常规功能签到、抽奖以及互动游戏、业绩目标评审定制等功能，让此次活动变得更加的精彩，现场来宾的参与感更强，定制化功能也为墨迹天气此次会议提供了更加智能的功能。',
          desc2: ''
        },{
          name: '混沌大学',
          avatar: require('./assets/img/avatar-02.png'),
          desc1: '现场通过连线抽奖嘉宾，滚动大屏幕抽取获奖者及其心愿，完成了线下参与到线上引爆的前后呼应。',
          desc2: '并结合有效的福利机制，最终员工对于公司美好祝愿得到有效激发和充分的互动。'
        },{
          name: '碧桂园',
          avatar: require('./assets/img/avatar-03.png'),
          desc1: '签到环节打破传统，通过弹幕上墙的方式进行签到，抽奖环节选用3D墙多人抽奖，一次性抽取30人，对比传统的抽奖方式，更加节省了时间。',
          desc2: '3D效果在大屏上显示，更加体现了年会的科技感。'
        }
      ],
      banner: require('./assets/img/banner.png'),
    }
    
  }

  handleRouter =(router)=>{
    window.location.href=router
  }
  handleMenuChange(index){
    this.setState({
      activeId: index
    })
  }
  render(){
    const { menus, activeId, scenes, products, tabList, currentTab, tabInfoList, banner } = this.state
    return(
      <div className="App">
        <div className="header">
          <div className="logo">
            <img src={logo} alt=""/>
          </div>
          <div className="menu-wrap">
              <div className="menu">
                {
                  menus.map((item,index)=>{
                    return <a 
                      key={`menu${index}`} 
                      className={activeId===index?'active item':'item'}
                      href={item.href}
                      target={index===3?'_blank':'_self'}
                      onClick={this.handleMenuChange.bind(this, index)}>
                        {item.name}
                    </a>
                  })
                }
              </div>
            <button className="login-btn" 
              onClick={this.handleRouter.bind(this,'https://yhh.rockysaas.com/login.htm')}>
                登陆/注册
            </button>
          </div>
        </div>
        <section className="section-1" style={{background: `url(${banner}) no-repeat center center`}}>
        </section>
        <section className="section-2">
          <div className="content">
            {
              scenes.map((item,index) => {
                return <div
                  className="box-item"
                  key={'scene'+index}>
                </div>
              })
            }
          </div>
        </section>
        <section className="section-3" id="product">
          <h3 className="title">
            <span>产品服务</span>
          </h3>
          <div className="content">
            {
              products.map((item,index) => {
                return <div className="box" key={'product'+index}>
                  <div className="box-item"></div>
                  <p className="name">{item.name}</p>
                  <p className="desc">{item.desc}</p>
                </div>
              })
            }
          </div>
        </section>
        <section className="section-4">
          <h3 className="title">
            <span>让现场嗨起来</span>
          </h3>
          
          <div className="content">
            <LunBo tabList={tabList} boxStyle="tab-info" activeIndex={currentTab}>
                {
                  tabInfoList.map((item,index) => {
                    return (
                      <li className="boxStyleLi" key={`tab-info-${index}`}>
                      {
                        tabInfoList[index].map((item, index) => {
                          return (
                            <div className='block-i' key={'tab-info'+index}>
                              <img className='icon-i' src={item.img} alt=""/>
                              <div className="text">
                                <span className="name">{item.name}</span>
                                <span className="desc">{item.desc}</span>
                              </div>
                            </div>
                          )
                        })
                      }
                      </li>
                    )
                  })
                }
            </LunBo>
          </div>
        </section>
        <section className="section-5">
          <h3 className="title">
            <span>多样玩法大屏展示</span>
          </h3>
          <div className="content">
            <img src={wayImg} alt=""/>
          </div>
        </section>
        <section className="section-6" id="serverCase">
          <h3 className="title">
            <span>客户服务案例</span>
          </h3>
          <div className="content">
            <div className="case">
              <img src={serverCase} alt=""/>
            </div>
            <div className="feedback">
              {
                this.state.feedback.map((item,index)=>{
                  return <div className="box-item" key={`feedback${index}`}>
                    <div className="name">
                      <img src={item.avatar} alt=""/>
                      <span>{item.name}</span>
                    </div>
                    <p>{item.desc1}</p>
                    <p>{item.desc2}</p>
                  </div>
                })
              }
            </div>
          </div>
        </section>
        <section className="section-7">
          <div className="content">
            <button className="btn"
              onClick={this.handleRouter.bind(this,'https://yhh.rockysaas.com/login.htm')}
            ></button>
            <button className="btn" 
              onClick={this.handleRouter.bind(this, 'https://www.wmnetwork.cc/screen/#/signwall?mid=wfp7e5qt')}>
            </button>
          </div>
        </section>
        <div className="footer">
          <div className="content">
            <div className="left">
              <img src={rockysaasLogo} alt=""/>
              <img src={logo1} alt=""/>
            </div>
            <div className="middle">
              <div className="one">
                <a href="#">云嗨会务</a>
                <a href="http://store.rockysaas.com/">RockySaaS生态</a>
                <a href="http://www.rockysaas.com/about">关于我们</a>
              </div>
              <div className="two">
                <p>客服电话: 400-100-1110</p>
              </div>
              <div className="copyright">
                Copyright © 2004-2019 浙江盘兴信息技术有限公司
                及All Rights Reserved  浙ICP备18048879号-2
                工信部备案查询网站
              </div>
            </div>
            <div className="right">
              <img src={scane} alt=""/>
              <p>扫一扫  了解更多信息</p>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default App;